#banner {
  padding-top: 20px;
  img {
    height: 50vh;
    object-fit: cover;
    @include media-breakpoint-down("md") {
      height: 80vh;
    }
  }
}

#our_ways {
  img {
    height: 380px;
    width: 100%;
    object-fit: cover;
    opacity: .7;
  }
}

.az-content-wrapper {
  min-height: 89vh;
}

#home {
  a {
    padding: 0;
    .card {
      img {
        height: 90px;
        object-fit: cover;
      }
    }
  }
}
