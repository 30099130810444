/* ############### FONTS IMPORT ############### */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800");
@import "~typicons.font/src/font/typicons.css";
@import "~ionicons/dist/css/ionicons.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~flag-icon-css/css/flag-icon.min.css";

// Bootstrap variable overrides
@import "../assets/scss/bootstrap/variables";
@import "../assets/scss/bootstrap/mixins";

/* ############### BOOTSTRAP FRAMEWORK ############### */
@import "~bootstrap/scss/bootstrap";

/* ############### Compass Mixins ############### */
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";

// Custom variables and mixins
@import "../assets/scss/custom/variables";
@import "../assets/scss/custom/mixins";

/* ########## BOOTSTRAP OVERRIDES ########## */
@import "../assets/scss/bootstrap/accordion";
@import "../assets/scss/bootstrap/alerts";
@import "../assets/scss/bootstrap/badge";
@import "../assets/scss/bootstrap/breadcrumb";
@import "../assets/scss/bootstrap/buttons";
@import "../assets/scss/bootstrap/cards";
@import "../assets/scss/bootstrap/carousel";
@import "../assets/scss/bootstrap/dropdown";
@import "../assets/scss/bootstrap/forms";
@import "../assets/scss/bootstrap/grid";
@import "../assets/scss/bootstrap/input-group";
@import "../assets/scss/bootstrap/modal";
@import "../assets/scss/bootstrap/nav";
@import "../assets/scss/bootstrap/pagination";
@import "../assets/scss/bootstrap/popover";
@import "../assets/scss/bootstrap/progress";
@import "../assets/scss/bootstrap/table";
@import "../assets/scss/bootstrap/toast";
@import "../assets/scss/bootstrap/tooltip";

/* ############### CUSTOM ELEMENT STYLES ############### */
@import "../assets/scss/custom/accordion";
@import "../assets/scss/custom/buttons";
@import "../assets/scss/custom/charts";
@import "../assets/scss/custom/forms";
@import "../assets/scss/custom/image";
@import "../assets/scss/custom/list";
@import "../assets/scss/custom/modal";
@import "../assets/scss/custom/nav";
@import "../assets/scss/custom/custom";

/* ############### CUSTOM VENDOR STYLES ############### */
@import "../assets/scss/lib/datatables";
@import "../assets/scss/lib/datepicker";
@import "../assets/scss/lib/datetimepicker";
@import "../assets/scss/lib/jquery-steps";
@import "../assets/scss/lib/parsley";
@import "../assets/scss/lib/jqvmap";
@import "../assets/scss/lib/quill";
@import "../assets/scss/lib/tiny";
@import "../assets/scss/lib/rangeslider";
@import "../assets/scss/lib/scrollbar";
@import "../assets/scss/lib/select2";
@import "../assets/scss/lib/spectrum";
@import "../assets/scss/lib/react-datepicker";
@import "../assets/scss/lib/nouislider";
@import "../assets/scss/lib/multistep";

/* ############### PAGE LAYOUT STYLES ############### */
@import "../assets/scss/layout/content";
@import "../assets/scss/layout/footer";
@import "../assets/scss/layout/global";
@import "../assets/scss/layout/header";
@import "../assets/scss/layout/iconbar";
@import "../assets/scss/layout/navbar";
@import "../assets/scss/layout/navbar-variant";
@import "../assets/scss/layout/sidebar";

/* ############### TEMPLATE STYLES ############### */
@import "../assets/scss/template/demo";

@import "../assets/scss/template/calendar";
@import "../assets/scss/template/chat";
@import "../assets/scss/template/contacts";
@import "../assets/scss/template/invoice";
@import "../assets/scss/template/mail";
@import "../assets/scss/template/mail-two";
@import "../assets/scss/template/profile";
@import "../assets/scss/template/signin";
@import "../assets/scss/template/signup";
@import "../assets/scss/template/error";

/* ############### THEME STYLES ############### */
@import "../assets/scss/template/theme";

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "../assets/scss/util/background";
@import "../assets/scss/util/border";
@import "../assets/scss/util/height";
@import "../assets/scss/util/margin";
@import "../assets/scss/util/misc";
@import "../assets/scss/util/padding";
@import "../assets/scss/util/position";
@import "../assets/scss/util/typography";
@import "../assets/scss/util/width";

/* ############### PLUGIN STYLES ############### */
@import "~react-perfect-scrollbar/dist/css/styles.css";
